export function getRepairServices(params: GetRepairServicesParams) {
  const { $api } = useNuxtApp()
  const formattedParams = {
    ...params,
    authorized: params.authorized ? params.authorized : undefined,
    has_price: params.has_price ? params.has_price : undefined,
  }

  return $api<ServicesPaginatedResult>('/repair-services/', {
    method: 'GET',
    params: formattedParams,
  })
}

export function repairRequest(data: RepairRequestData) {
  const { $api } = useNuxtApp()

  return $api('/repair-requests/', {
    method: 'POST',
    body: data,
    onResponseError: (error) => {
      throw error
    },
  });
}

export function repairAsk(data: RepairAskData) {
  const { $api } = useNuxtApp()

  return $api('/repair-asks/', {
    method: 'POST',
    body: data,
    onResponseError: (error) => {
      throw error
    },
  });
}
